import dayjs from 'dayjs'
import React, { useMemo } from 'react'

import { formatDateInput as formatDate } from '../../../lib/date'
import {
  UpsertUserOneOnOneMeetingsInput,
  useUserOneOnOneMeetingsQuery,
  UserOneOnOneMeeting,
} from '../graphql'

import {
  ModalSubmitHandler,
  OneOnOneModal,
  SelectedPartner,
  Disabled,
  Props as OneOnOneModalProps,
} from './OneOnOneModal'
import { TIME_FORMAT } from './OneOnOneModalTimeParts'
import { FormValue, Partner } from './type'

export type Props = {
  title: string
  oneOnOneId: string
  currentOneOnOneMeeting?: Pick<
    UserOneOnOneMeeting,
    'id' | 'startTime' | 'endTime' | 'description' | 'descriptionPlainText'
  >
  selectedPartner?: SelectedPartner
  onClose: () => void
  onUpsertAndDeleteUserOneOnOneMeetings: (
    id: string,
    deleteIDs: ReadonlyArray<string>,
    upsertUserOneOnOneMeetingsInput: Array<UpsertUserOneOnOneMeetingsInput>,
    partner: Partner,
  ) => Promise<void>
}

const DISABLED_FIELD: Disabled = { partner: true }

const createUpsertUserOneOnOneMeetingsInput = (
  meetingSchedule: FormValue['meetingSchedule'][number],
) => {
  const ymd = dayjs(meetingSchedule.date).format('YYYY/MM/DD')

  return {
    id: meetingSchedule.id,
    schedule: {
      startTime: new Date(`${ymd} ${meetingSchedule.startTime}:00`),
      endTime: new Date(`${ymd} ${meetingSchedule.endTime}:00`),
    },
    description: {
      description: meetingSchedule.description.treeJson,
      descriptionPlainText: meetingSchedule.description.plainText,
    },
  }
}

export const OneOnOneEditMeetingScheduleModal: React.FC<Props> = ({
  title,
  oneOnOneId,
  currentOneOnOneMeeting,
  selectedPartner,
  onClose,
  onUpsertAndDeleteUserOneOnOneMeetings,
}) => {
  const onSubmit: ModalSubmitHandler = async (value: FormValue) => {
    const deleteIDs: ReadonlyArray<string> =
      meetings
        ?.filter(
          (meeting) =>
            meeting.id && value.meetingSchedule.findIndex((m) => m.id === meeting.id) === -1,
        )
        .map((meeting) => meeting.id)
        .reduce(
          (prev: ReadonlyArray<string>, current) => (current ? prev.concat([current]) : prev),
          [],
        ) ?? []

    const upsertUserOneOnOneMeetingsInput = value.meetingSchedule
      .map<UpsertUserOneOnOneMeetingsInput>((s) => createUpsertUserOneOnOneMeetingsInput(s))
      .concat(
        value.currentMeeting &&
          dayjs(`${formatDate(value.currentMeeting.date)} ${value.currentMeeting.endTime}`).isAfter(
            dayjs(),
          )
          ? [createUpsertUserOneOnOneMeetingsInput(value.currentMeeting)]
          : [],
      )

    await onUpsertAndDeleteUserOneOnOneMeetings(
      oneOnOneId,
      deleteIDs,
      upsertUserOneOnOneMeetingsInput,
      value.partner,
    )
  }

  const now = useMemo(() => dayjs(), [])
  const { data: meetingsData, loading } = useUserOneOnOneMeetingsQuery({
    variables: { userOneOnOneId: oneOnOneId, now: now.toDate() },
  })

  const currentMeeting = useMemo<OneOnOneModalProps['currentMeeting']>(
    () =>
      currentOneOnOneMeeting
        ? {
            id: currentOneOnOneMeeting.id,
            date: dayjs(currentOneOnOneMeeting.startTime).toDate(),
            startTime: dayjs(currentOneOnOneMeeting.startTime).format(TIME_FORMAT),
            endTime: dayjs(currentOneOnOneMeeting.endTime).format(TIME_FORMAT),
            description: {
              treeJson: currentOneOnOneMeeting.description,
              plainText: currentOneOnOneMeeting.descriptionPlainText,
            },
          }
        : undefined,
    [currentOneOnOneMeeting],
  )

  const meetings = useMemo<NonNullable<OneOnOneModalProps['meetings']> | null>(() => {
    if (loading) {
      return null
    }

    if (!meetingsData || meetingsData.userOneOnOneMeetings.length === 0) {
      return []
    }

    const userOneOnOneMeetings = currentMeeting?.id
      ? meetingsData.userOneOnOneMeetings.filter(
          (userOneOnOneMeeting) => currentMeeting.id !== userOneOnOneMeeting.id,
        )
      : meetingsData.userOneOnOneMeetings

    return userOneOnOneMeetings.map<NonNullable<OneOnOneModalProps['meetings']>[number]>(
      (meeting) => ({
        id: meeting.id,
        date: dayjs(meeting.startTime).toDate(),
        startTime: dayjs(meeting.startTime).format(TIME_FORMAT),
        endTime: dayjs(meeting.endTime).format(TIME_FORMAT),
        description: {
          treeJson: meeting.description,
          plainText: meeting.descriptionPlainText,
        },
      }),
    )
  }, [currentMeeting?.id, loading, meetingsData])

  if (loading || meetings === null) return null

  return (
    <OneOnOneModal
      title={title}
      selectedPartner={selectedPartner}
      currentMeeting={currentMeeting}
      meetings={meetings}
      disabled={DISABLED_FIELD}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}
