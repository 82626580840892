import dayjs from 'dayjs'
import { Box, Grid } from 'grommet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PageContent } from '../../components/pageContent'
import { Avatar } from '../../components/ui/Avatar'
import { Button } from '../../components/ui/Button'
import { Type as IconType } from '../../components/ui/Icon'
import { StyledText } from '../../components/ui/StyledText'
import { useCurrentUser } from '../../contexts/UserContext'
import { useTranslation } from '../../i18n'
import {
  getUserGuidingEventFired,
  updateUserGuidingEventFired,
} from '../../lib/localStorage/userGuiding'
import { Screen } from '../../lib/screen'
import { useTracking } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'

import { GettingStartedCard } from './GettingStartedCard'
import { OnCreateUserOneOnOneMeetings, OneOnOneCreateModal } from './Modal/OneOnOneCreateModal'
import { OneOnOneEditMeetingScheduleModal } from './Modal/OneOnOneEditMeetingScheduleModal'
import { Partner } from './Modal/type'
import { OneOnOneCardList, Props as OneOnOneCardListProps } from './OneOnOneCardList'
import {
  OneOnOneUserFragment,
  useDeleteOneOnOneMutation,
  useCreateUserOneOnOneMeetingsMutation,
  UserOneOnOnesDocument,
  UserOneOnOnesQuery,
  UserOneOnOnesQueryVariables,
  useUserOneOnOnesQuery,
  CreateUserOneOnOneMeetingInput,
  UpsertUserOneOnOneMeetingsInput,
  useUpsertAndDeleteUserOneOnOneMeetingsMutation,
} from './graphql'

export type Trend = {
  id: string
  iconType: IconType
  text: string
  disabled: boolean
}

const USER_TRENDS_WIDTH = 380

const closedModalType: ModalType = { type: 'close' }

type ModalType =
  | { type: 'close' }
  | { type: 'createOneOnOne' }
  | {
      type: 'editMeetings'
      oneOnOneId: string
      partner: {
        user: OneOnOneUserFragment
      }
    }

export const OneOnOnesContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  const now = useMemo<Date>(() => dayjs().startOf('minute').toDate(), [])

  useTracking(t('ONEONONE_PAGE_TITLE'), Screen.OneOnOne)

  const [modalType, setModalType] = useState<ModalType>(closedModalType)

  const { data } = useUserOneOnOnesQuery({
    // NOTE: 古い情報が表示されてしまうためキャッシュを無効にする
    fetchPolicy: 'network-only',
    variables: { now },
  })

  const [createUserOneOnOneMeetingsMutation] = useCreateUserOneOnOneMeetingsMutation()
  const [upsertAndDeleteUserOneOnOneMeetingsMutation] =
    useUpsertAndDeleteUserOneOnOneMeetingsMutation()
  const [deleteOneOnOneMutation] = useDeleteOneOnOneMutation()

  const handleCreateUserOneOnOneMeetings: OnCreateUserOneOnOneMeetings = async (
    partner: Partner,
    userOneOnOneMeetingsInput: Array<CreateUserOneOnOneMeetingInput>,
  ) => {
    if (!user || !data) return

    const result = await createUserOneOnOneMeetingsMutation({
      variables: {
        input: {
          partnerID: partner.id,
          UserOneOnOneMeetingsInput: userOneOnOneMeetingsInput,
          notifierInput: {
            notifierIntegrationField: {
              chatworkIntegrated: false,
              slackIntegrated: false,
              teamsIntegrated: false,
            },
            toUser: {
              email: partner.email,
              firstName: partner.firstName,
              lastName: partner.lastName,
              language: partner.language,
              notifyEmailEnabled: partner.notifyEmailEnabled,
            },
            triggeredUser: {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              language: user.userSetting.language,
              notifyEmailEnabled: user.userSetting.notifyEmailEnabled,
            },
          },
        },
        now,
      },
      update: (cache, { data: createUserOneOnOneMeetingsRes }) => {
        const option = {
          query: UserOneOnOnesDocument,
          variables: { now },
        }

        const { userOneOnOnes } =
          cache.readQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>(option) || {}

        if (!createUserOneOnOneMeetingsRes?.createUserOneOnOneMeetings || !userOneOnOnes) {
          return
        }
        const { createUserOneOnOneMeetings: userOneOnOneRes } = createUserOneOnOneMeetingsRes

        cache.writeQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>({
          query: option.query,
          data: {
            userOneOnOnes: [
              ...userOneOnOnes,
              {
                __typename: 'UserOneOnOne',
                ...userOneOnOneRes,
              },
            ],
          },
        })
      },
    })
    if (!result.data) return
    setModalType(closedModalType)
  }

  const handleUpsertAndDeleteUserOneOnOneMeetings = async (
    id: string,
    deleteIDs: ReadonlyArray<string>,
    upsertUserOneOnOneMeetingsInput: Array<UpsertUserOneOnOneMeetingsInput>,
    partner: Partner,
  ) => {
    if (!user) return

    const result = await upsertAndDeleteUserOneOnOneMeetingsMutation({
      variables: {
        input: {
          id,
          deleteIDs,
          UserOneOnOneMeetingsInput: upsertUserOneOnOneMeetingsInput,
          notifierInput: {
            notifierIntegrationField: {
              chatworkIntegrated: false,
              slackIntegrated: false,
              teamsIntegrated: false,
            },
            toUser: {
              email: partner.email,
              firstName: partner.firstName,
              lastName: partner.lastName,
              language: partner.language,
              notifyEmailEnabled: partner.notifyEmailEnabled,
            },
            triggeredUser: {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              language: user.userSetting.language,
              notifyEmailEnabled: user.userSetting.notifyEmailEnabled,
            },
          },
        },
        now,
      },
      update: (cache, { data: upsertAndDeleteUserOneOnOneMeetingsRes }) => {
        const option = {
          query: UserOneOnOnesDocument,
          variables: { now },
        }

        const { userOneOnOnes } =
          cache.readQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>(option) || {}

        if (
          !upsertAndDeleteUserOneOnOneMeetingsRes?.upsertAndDeleteUserOneOnOneMeetings ||
          !userOneOnOnes
        ) {
          return
        }
        const { upsertAndDeleteUserOneOnOneMeetings: userOneOnOneRes } =
          upsertAndDeleteUserOneOnOneMeetingsRes

        cache.writeQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>({
          query: option.query,
          data: {
            userOneOnOnes: [
              ...userOneOnOnes.map((userOneOnOne) =>
                userOneOnOne.id === userOneOnOneRes.id ? userOneOnOneRes : userOneOnOne,
              ),
            ],
          },
        })
      },
    })
    if (!result.data) return
    setModalType(closedModalType)
  }

  const deleteOneOnOne = async (id: string, partner: Partner) => {
    if (!user) return

    await deleteOneOnOneMutation({
      variables: {
        id,
        notifierInput: {
          notifierIntegrationField: {
            chatworkIntegrated: false,
            slackIntegrated: false,
            teamsIntegrated: false,
          },
          toUser: {
            email: partner.email,
            firstName: partner.firstName,
            lastName: partner.lastName,
            language: partner.language,
            notifyEmailEnabled: partner.notifyEmailEnabled,
          },
          triggeredUser: {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            language: user.userSetting.language,
            notifyEmailEnabled: user.userSetting.notifyEmailEnabled,
          },
        },
      },
      update(cache, { data: deletedData }) {
        const option = {
          query: UserOneOnOnesDocument,
          variables: { now },
        }
        const { userOneOnOnes } =
          cache.readQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>(option) || {}

        if (!userOneOnOnes || !deletedData) {
          return
        }

        cache.writeQuery<UserOneOnOnesQuery, UserOneOnOnesQueryVariables>({
          ...option,
          data: {
            userOneOnOnes: userOneOnOnes.filter(
              (userOneOnOne) => userOneOnOne.id !== deletedData.deleteOneOnOne,
            ),
          },
        })
      },
    })
  }

  const handleCreateOneOnOne = () => {
    setModalType({ type: 'createOneOnOne' })
    const guideId = 65448
    if (window != null && !getUserGuidingEventFired(guideId)) {
      window.userGuiding.previewGuide(guideId)
      updateUserGuidingEventFired(guideId)
    }
  }

  const handleEditMeeting: OneOnOneCardListProps['handleEditMeeting'] = useCallback((oneOnOne) => {
    setModalType({
      type: 'editMeetings',
      oneOnOneId: oneOnOne.oneOnOneId,
      partner: oneOnOne.partner,
    })
  }, [])

  const handleModalClose = useCallback(() => {
    setModalType(closedModalType)
  }, [])

  const userOneOnOneQuery = data?.userOneOnOnes

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  if (!user || !userOneOnOneQuery) {
    return null
  }

  return (
    <PageContent breadcrumbs={undefined}>
      <Grid
        fill
        columns={[`${USER_TRENDS_WIDTH}px`, 'flex']}
        rows={['flex']}
        areas={[
          { name: 'user-trends', start: [0, 0], end: [1, 0] },
          { name: 'user-selection', start: [1, 0], end: [1, 0] },
        ]}
      >
        <Box css={{ backgroundColor: '#F8F9F9' }} direction="column">
          <Box
            align="center"
            border={{
              side: 'bottom',
              color: color('border-bk-10'),
            }}
            pad={{
              horizontal: '32px',
              top: '40px',
              bottom: '16px',
            }}
          >
            <Avatar
              className="avatar-icon"
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatar?.url}
              isUserDisabled={user.isDisabled}
              size="one-on-one-dashboard-page"
            />
            <div css={{ marginTop: '16px' }}>
              <StyledText
                css={{
                  fontSize: '20px',
                  fontWeight: 700,
                }}
              >
                {t('X_OF_Y', {
                  x: t('ONEONONE'),
                  y: t('FULL_NAME', { firstName: user.firstName, lastName: user.lastName }),
                })}
              </StyledText>
            </div>
            <div css={{ marginTop: '16px' }}>
              <ul
                css={{
                  display: 'flex',
                  listStyle: 'none',
                  flexWrap: 'wrap',
                  wordBreak: 'break-word',
                  '&>li': {
                    paddingRight: '8px',
                    margin: '2px 0',
                  },
                  '&>li:not(:first-of-type)': {
                    padding: '0 8px',
                    borderLeft: `1px solid ${color('border-bk-10')}`,
                  },
                }}
              >
                {user.groups?.map((group) => (
                  <li key={group.name}>
                    <StyledText size="small" color="text-bk-50">
                      {group.name}
                    </StyledText>
                  </li>
                ))}
              </ul>
            </div>
          </Box>

          {/*
        <Box
          pad={{
            horizontal: '32px',
            top: '32px',
          }}
        >

          <div css={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
            <StyledText
              weight={700}
              css={{
                fontSize: '20px',
                marginRight: '8px',
              }}
            >
              {t('USER_TREND')}
            </StyledText>
            <PopoverPortal
              icon="help"
              iconCss={{ color: color('text-bk-100'), display: 'flex' }}
              text={t('USER_TREND_HELP')}
            />
          </div>
          <UserTrendsButtonList trends={trends} onClickTrend={onClickTrend} />
        </Box>
        */}
        </Box>

        {/* 新規登録 */}
        <div css={{ overflowY: 'auto', padding: '24px 32px' }}>
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              css={{ marginRight: '24px' }}
              newColor="resily-orange-100"
              size="s"
              onClick={handleCreateOneOnOne}
            >
              {t('CREATE_ONEONONE')}
            </Button>
          </div>
          <div css={{ marginTop: '56px' }}>
            <StyledText css={{ fontSize: '20px' }} weight="bold">
              {t('SELECT_X', { x: t('X_OF_Y', { x: t('PARTNER'), y: t('ONEONONE') }) })}
            </StyledText>
          </div>

          {userOneOnOneQuery.length ? (
            <OneOnOneCardList
              oneOnOnes={userOneOnOneQuery}
              handleEditMeeting={handleEditMeeting}
              onDeleteOneOnOne={deleteOneOnOne}
            />
          ) : (
            <GettingStartedCard />
          )}
          {modalType.type === 'createOneOnOne' && (
            <OneOnOneCreateModal
              title={t('CREATE_ONEONONE_MODAL_TITLE')}
              onCreateUserOneOnOneMeetings={handleCreateUserOneOnOneMeetings}
              onClose={handleModalClose}
            />
          )}
          {modalType.type === 'editMeetings' && (
            <OneOnOneEditMeetingScheduleModal
              title={t('EDIT_ONEONONE_MODAL_TITLE')}
              oneOnOneId={modalType.oneOnOneId}
              selectedPartner={modalType.partner}
              onUpsertAndDeleteUserOneOnOneMeetings={handleUpsertAndDeleteUserOneOnOneMeetings}
              onClose={handleModalClose}
            />
          )}
        </div>
      </Grid>
    </PageContent>
  )
}

OneOnOnesContainer.displayName = 'OneOnOnesContainer'
