import dayjs from 'dayjs'
import { Box } from 'grommet'
import { FormNext } from 'grommet-icons'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageContent } from '../../components/pageContent'
import { Link } from '../../components/ui/Link'
import { useCurrentUser } from '../../contexts/UserContext'
import { TFunction, useTranslation } from '../../i18n'
import { Screen, findScreen } from '../../lib/screen'
import { useTracking, tracker } from '../../lib/tracking'
import { color } from '../../styles/newColors'
import { AuthRouteProps } from '../../types/authRouteProps'
import {
  generateOneOnOne,
  generateOneOnOneDetail,
  generateOneOnOneHistory,
  ExternalUrls,
  ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID,
  ONE_ON_ONE_DRAWER_QUERY_TERM_ID,
  ONE_ON_ONE_DRAWER_QUERY_USER_ID,
} from '../../urls'
import { OneOnOneEditMeetingScheduleModal } from '../OneOnOnes/Modal/OneOnOneEditMeetingScheduleModal'
import { Partner } from '../OneOnOnes/Modal/type'

import { OneOnOneDrawer } from './Drawer'
import { useDrawerState, useOpenDrawer, useReplaceDrawerState } from './Drawer/hooks/useDrawerState'
import { Props as HeaderProps, Header } from './Header'
import { LocationAndUrlSpace } from './LocationAndUrlSpace'
import { OneOnOneDeleteModal } from './OneOnOneDeleteModal'
import { OneOnOneSection } from './OneOnOneSection'
import { Tips, Props as TipsProps } from './OneOnOneTips'
import { getTipsItem } from './OneOnOneTips/TipsList'
import {
  TermFragment,
  useUserOneOnOneQuery,
  useOkrTermsQuery,
  useDeleteOneOnOneMeetingMutation,
  useOneOnOneMeetingsQuery,
  OneOnOneMeetingsDocument,
  OneOnOneMeetingsQuery,
  OneOnOneMeetingsQueryVariables,
  UserOneOnOneMeetingEdgeFragment,
  useUserOneOnOneMeetingQuery,
  useUserOneOnOnePastMeetingsLazyQuery,
  UpsertUserOneOnOneMeetingsInput,
  useUpsertAndDeleteUserOneOnOneMeetingsMutation,
} from './graphql'

const DATE_FORMAT = 'YYYY/MM/DD'
const TIME_FORMAT = 'H:mm'
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT} `

const PAGING_COUNT = 10
const VARIABLES = {
  first: PAGING_COUNT,
  after: null,
  last: null,
  before: null,
}

/**
 * 指定の日付がOKR期間内かどうか
 *
 * @param {TermFragment} term OKR期間
 * @param {Date} date 指定した日付
 *
 *  @returns boolean
 *
 */
const containsTerm = (term: TermFragment, date: Date): boolean =>
  term.startDate <= date && date <= term.endDate

/**
 * 現在のミーティング以外のミーティングを返す
 * 返却ロジックは以下
 * 1. 現在のミーティングしか存在しない場合は、nullを返却
 * 2. 現在のミーティングが最も古いミーティングの場合は、次に古いミーティングを返却
 * 3. 現在のミーティングより過去に実施したミーティングが存在する場合は、1つ前のミーティングを返却
 *
 * @param {string} currentMeetingId 現在のミーティングのID
 * @param {ReadonlyArray<UserOneOnOneMeetingEdgeFragment>}  meetings 全てのミーティング
 *
 *  @returns ミーティング || null
 *
 */
const findOtherMeeting = (
  currentMeetingId: string,
  meetings: ReadonlyArray<UserOneOnOneMeetingEdgeFragment>,
): UserOneOnOneMeetingEdgeFragment | null => {
  const currentMeetingIndex = meetings.findIndex((meeting) => meeting.node.id === currentMeetingId)
  const excludeCurrentMeetings = meetings.filter((meeting) => meeting.node.id !== currentMeetingId)

  // ミーティングが見つからない or 現在のミーティング以外が存在しない
  if (currentMeetingIndex < 0 || excludeCurrentMeetings.length === 0) {
    return null
  }

  // 現在のミーティングが最も古い場合は、次に古いミーティングを返却
  // 最も古くない場合は、現在の一つ前に実施したミーティングを返却する
  return excludeCurrentMeetings.length - 1 < currentMeetingIndex
    ? excludeCurrentMeetings[currentMeetingIndex - 1]
    : excludeCurrentMeetings[currentMeetingIndex]
}

// 「1on1のドロワーを開く」のイベントをトラッキング
const openDrawerTrackingEvent = (userId: string, isPartnerUserDrawer?: boolean) =>
  tracker.UserClickOpenOneOnOneDrawer(
    findScreen(window.location.pathname),
    userId,
    isPartnerUserDrawer,
  )

// 1on1の日程削除モーダルの削除対象の日程の名前を生成する
const generateDeleteItemName = (t: TFunction, startTime: Date, endTime: Date): string =>
  t('X_OF_Y', {
    x: t('ONEONONE'),
    y: `${dayjs(startTime).format(DATE_TIME_FORMAT)} ~ ${dayjs(endTime).format(TIME_FORMAT)}`,
  })

export const OneOnOnesDetailContainer: React.FC<AuthRouteProps> = ({ onOkrTermLoaded }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { oneOnOneId = '', oneOnOneMeetingId = '' } = useParams()
  const { openable: openableDrawer, params } = useDrawerState()
  useTracking(t('ONEONONE_DETAIL_PAGE_TITLE'), Screen.OneOnOneDetail)
  const now = useMemo<Date>(() => dayjs().startOf('minute').toDate(), [])

  const { data: okrTermsData } = useOkrTermsQuery()
  const currentUser = useCurrentUser()

  useEffect(() => {
    onOkrTermLoaded(null)
  }, [onOkrTermLoaded])

  const { data: userOneOnOne } = useUserOneOnOneQuery({
    variables: { id: oneOnOneId, now },
  })

  const [userOneOnOnePastMeetingsCountQuery, { data: userOneOnOnePastMeetings }] =
    useUserOneOnOnePastMeetingsLazyQuery()

  const { data: oneOnOneMeeting } = useUserOneOnOneMeetingQuery({
    variables: { id: oneOnOneMeetingId },
    onCompleted: () => {
      if (!oneOnOneMeeting) {
        return
      }
      const {
        userOneOnOneMeeting: { endTime },
      } = oneOnOneMeeting

      userOneOnOnePastMeetingsCountQuery({
        variables: { id: oneOnOneId, endTime },
      })
    },
  })
  const previousMeeting = userOneOnOnePastMeetings?.userOneOnOne.previousMeetings.edges[0]
  const currentMeeting = oneOnOneMeeting?.userOneOnOneMeeting
  const { data: oneOnOneMeetings, fetchMore } = useOneOnOneMeetingsQuery({
    variables: { userOneOnOneId: oneOnOneId, ...VARIABLES },
  })

  const meetings = oneOnOneMeetings?.meetings?.meetings.edges

  const currentMeetingTerm = useMemo<TermFragment | undefined>(
    () =>
      okrTermsData?.okrTerms.find(
        (okrTerm) => currentMeeting && containsTerm(okrTerm, currentMeeting.dateOfMeeting),
      ) ?? undefined,
    [currentMeeting, okrTermsData?.okrTerms],
  )

  const users = useMemo<HeaderProps['users']>(
    () =>
      userOneOnOne
        ? [userOneOnOne.userOneOnOne.me, userOneOnOne.userOneOnOne.partnerUser]
        : undefined,
    [userOneOnOne],
  )

  /**  ヘッダー */
  const openDrawer = useOpenDrawer()
  const replaceDrawerState = useReplaceDrawerState()
  const onClickTitleItem = useCallback<HeaderProps['onClickTitleItem']>(
    (meetingId: string) => {
      navigate(
        generateOneOnOneDetail({
          oneOnOneId,
          oneOnOneMeetingId: meetingId,
          [ONE_ON_ONE_DRAWER_QUERY_TERM_ID]: params?.termId,
          [ONE_ON_ONE_DRAWER_QUERY_USER_ID]: params?.userId,
          [ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID]: params?.oneOnOneId,
        }),
      )
    },
    [navigate, oneOnOneId, params?.oneOnOneId, params?.termId, params?.userId],
  )
  const historyUrl = generateOneOnOneHistory(oneOnOneId)

  const handleReachEndMeetings = () => {
    const endCursor = oneOnOneMeetings?.meetings.meetings.pageInfo.endCursor
    const hasNextPage = oneOnOneMeetings?.meetings.meetings.pageInfo.hasNextPage
    if (!endCursor || !hasNextPage) {
      return
    }

    fetchMore({
      variables: {
        first: PAGING_COUNT,
        after: endCursor,
        last: null,
        before: null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const previousEdges =
          previousResult?.meetings?.meetings?.edges || oneOnOneMeetings?.meetings?.meetings?.edges
        const fetchMoreEdges = fetchMoreResult.meetings.meetings.edges

        return {
          ...fetchMoreResult,
          meetings: {
            ...fetchMoreResult.meetings,
            meetings: {
              ...fetchMoreResult.meetings.meetings,
              edges: [...previousEdges, ...fetchMoreEdges],
            },
          },
        }
      },
    })
  }

  const onClickAvatar = useCallback<HeaderProps['onClickAvatar']>(
    (_, user) => {
      const isPartnerDrawer = userOneOnOne ? userOneOnOne.userOneOnOne.me.id !== user.id : undefined
      if (openableDrawer) {
        // アバタークリックで「1on1のドロワーを開く」のイベントをトラッキング
        openDrawerTrackingEvent(user.id, isPartnerDrawer)
        replaceDrawerState({ userId: user.id })
        return
      }
      if (okrTermsData) {
        // ミーティングの開催日のOKR期間を返す、なければ現在のOKR期間を返す
        const termId =
          okrTermsData?.okrTerms.find(
            (okrTerm) => currentMeeting && containsTerm(okrTerm, currentMeeting.dateOfMeeting),
          )?.id ?? okrTermsData?.okrTerms.find((okrTerm) => okrTerm.primary)?.id
        if (termId) {
          // アバタークリックで「1on1のドロワーを開く」のイベントをトラッキング
          openDrawerTrackingEvent(user.id, isPartnerDrawer)
          openDrawer({ termId, oneOnOneId, userId: user.id })
        }
      }
    },
    [
      userOneOnOne,
      openableDrawer,
      okrTermsData,
      replaceDrawerState,
      currentMeeting,
      openDrawer,
      oneOnOneId,
    ],
  )

  const [isShowUpdateMeetingModal, setIsShowUpdateMeetingModal] = useState(false)
  const [isShowDeleteMeetingModal, setIsShowDeleteMeetingModal] = useState(false)

  const onClickUpdateMeeting = useCallback(() => {
    setIsShowUpdateMeetingModal(true)
  }, [])

  const onClickDeleteMeeting = useCallback(() => {
    setIsShowDeleteMeetingModal(true)
  }, [])

  const handleUpdateMeetingModalClose = useCallback(() => {
    setIsShowUpdateMeetingModal(false)
  }, [])

  const [deleteOneOnOneMeetingMutation] = useDeleteOneOnOneMeetingMutation({
    onCompleted: (data) => {
      const oneOnOnesHistoryReplace = () => navigate(generateOneOnOne(), { replace: true })

      if (!meetings) {
        oneOnOnesHistoryReplace()
        return
      }

      const destinationMeeting = findOtherMeeting(data.deleteOneOnOneMeeting, meetings)

      if (!destinationMeeting) {
        oneOnOnesHistoryReplace()
        return
      }

      navigate(
        generateOneOnOneDetail({
          oneOnOneId,
          oneOnOneMeetingId: destinationMeeting.node.id,
          [ONE_ON_ONE_DRAWER_QUERY_TERM_ID]: params?.termId,
          [ONE_ON_ONE_DRAWER_QUERY_USER_ID]: params?.userId,
          [ONE_ON_ONE_DRAWER_QUERY_ONE_ON_ONE_ID]: params?.oneOnOneId,
        }),
        { replace: true },
      )
    },
  })

  const [upsertAndDeleteUserOneOnOneMeetingsMutation] =
    useUpsertAndDeleteUserOneOnOneMeetingsMutation()

  const handleUpsertAndDeleteUserOneOnOneMeetings = async (
    id: string,
    deleteIDs: ReadonlyArray<string>,
    upsertUserOneOnOneMeetingsInput: Array<UpsertUserOneOnOneMeetingsInput>,
    partner: Partner,
  ) => {
    if (!currentUser) return

    const result = await upsertAndDeleteUserOneOnOneMeetingsMutation({
      variables: {
        input: {
          id,
          deleteIDs,
          UserOneOnOneMeetingsInput: upsertUserOneOnOneMeetingsInput,
          notifierInput: {
            notifierIntegrationField: {
              chatworkIntegrated: false,
              slackIntegrated: false,
              teamsIntegrated: false,
            },
            toUser: {
              email: partner.email,
              firstName: partner.firstName,
              lastName: partner.lastName,
              language: partner.language,
              notifyEmailEnabled: partner.notifyEmailEnabled,
            },
            triggeredUser: {
              email: currentUser.email,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              language: currentUser.userSetting.language,
              notifyEmailEnabled: currentUser.userSetting.notifyEmailEnabled,
            },
          },
        },
      },
      update: (cache, { data: upsertAndDeleteUserOneOnOneMeetingsRes }) => {
        const option = {
          query: OneOnOneMeetingsDocument,
          variables: { userOneOnOneId: oneOnOneId, ...VARIABLES },
        }

        const { meetings: cacheUserOneOnOne } =
          cache.readQuery<OneOnOneMeetingsQuery, OneOnOneMeetingsQueryVariables>(option) || {}

        if (
          !upsertAndDeleteUserOneOnOneMeetingsRes?.upsertAndDeleteUserOneOnOneMeetings ||
          !cacheUserOneOnOne
        ) {
          return
        }
        const { upsertAndDeleteUserOneOnOneMeetings: userOneOnOneRes } =
          upsertAndDeleteUserOneOnOneMeetingsRes

        cache.writeQuery<OneOnOneMeetingsQuery, OneOnOneMeetingsQueryVariables>({
          query: option.query,
          variables: { userOneOnOneId: oneOnOneId, ...VARIABLES },
          data: {
            meetings: {
              ...cacheUserOneOnOne,
              meetings: {
                ...userOneOnOneRes.meetings,
              },
            },
          },
        })
      },
    })
    if (!result.data) return
    handleUpdateMeetingModalClose()
  }

  const deleteOneOnOneMeeting = async (id: string) => {
    await deleteOneOnOneMeetingMutation({
      variables: { id },
      update: (cache, { data: deletedMeetingId }) => {
        const option = {
          query: OneOnOneMeetingsDocument,
          variables: { userOneOnOneId: oneOnOneId, ...VARIABLES },
        }

        const { meetings: cacheMeetings } =
          cache.readQuery<OneOnOneMeetingsQuery, OneOnOneMeetingsQueryVariables>(option) || {}

        if (!cacheMeetings || !deletedMeetingId) {
          return
        }
        const deletedId = deletedMeetingId.deleteOneOnOneMeeting
        const excludeDeletedMeetingEdges = cacheMeetings.meetings.edges.filter(
          (edge) => edge.node.id !== deletedId,
        )

        cache.writeQuery<OneOnOneMeetingsQuery, OneOnOneMeetingsQueryVariables>({
          query: option.query,
          variables: { userOneOnOneId: oneOnOneId, ...VARIABLES },
          data: {
            meetings: {
              ...cacheMeetings,
              meetings: {
                ...cacheMeetings.meetings,
                totalCount: cacheMeetings.meetings.totalCount - 1,
                pageInfo: {
                  ...cacheMeetings.meetings.pageInfo,
                  startCursor:
                    excludeDeletedMeetingEdges.length > 0
                      ? excludeDeletedMeetingEdges[0].cursor
                      : null,
                  endCursor:
                    excludeDeletedMeetingEdges.length > 0
                      ? excludeDeletedMeetingEdges[excludeDeletedMeetingEdges.length - 1].cursor
                      : null,
                },
                edges: {
                  ...excludeDeletedMeetingEdges,
                },
              },
            },
          },
        })
      },
    })
  }

  const tipsItem = useMemo<TipsProps['item']>(() => {
    if (!userOneOnOnePastMeetings) {
      return undefined
    }
    return getTipsItem(userOneOnOnePastMeetings.userOneOnOne.pastMeetingsCount.totalCount)
  }, [userOneOnOnePastMeetings])

  const editorId = `locationAndUrl-${oneOnOneMeetingId}`

  const description = {
    json: currentMeeting?.description ?? '',
    plainText: currentMeeting?.descriptionPlainText ?? '',
  }

  const pastMeetingsCount = userOneOnOne?.userOneOnOne.pastMeetings.totalCount ?? 0
  const isShowOneOnOneHistoryLink = pastMeetingsCount > 0

  return (
    <PageContent
      breadcrumbs={undefined}
      header={
        <Header
          currentMeeting={currentMeeting}
          users={users}
          meetings={meetings ?? []}
          isShowOneOnOneHistoryLink={isShowOneOnOneHistoryLink}
          oneOnOneHistoryUrl={historyUrl}
          onClickTitleItem={onClickTitleItem}
          onClickAvatar={onClickAvatar}
          onClickDeleteMeeting={onClickDeleteMeeting}
          onTitleSelectReachEnd={handleReachEndMeetings}
          onClickUpdateMeeting={onClickUpdateMeeting}
        />
      }
    >
      <Box
        css={{
          // stylelint-disable-next-line
          display: '-webkit-box',
          height: '100%',
          width: '100%',
        }}
      >
        <>
          <div css={{ flexGrow: 1 }}>
            {currentMeeting && userOneOnOne && (
              <OneOnOneSection
                currentUser={userOneOnOne.userOneOnOne.me}
                partnerUser={userOneOnOne.userOneOnOne.partnerUser}
                oneOnOneMeetingId={oneOnOneMeetingId}
                termId={currentMeetingTerm?.id}
                currentMeetingAt={{
                  startTime: currentMeeting.startTime,
                  endTime: currentMeeting.endTime,
                }}
                previousMeetingAt={
                  previousMeeting
                    ? {
                        startTime: previousMeeting.node.startTime,
                        endTime: previousMeeting.node.endTime,
                      }
                    : undefined
                }
                privateNote={currentMeeting.privateNote}
              />
            )}
          </div>
          <div
            style={{
              display: openableDrawer ? 'none' : undefined,
            }}
            css={{
              background: color('hover-background-bk-5'),
              padding: '32px',
              height: `100%`,
              overflowY: 'scroll',
            }}
          >
            <LocationAndUrlSpace editorId={editorId} description={description} />
            <Link
              method="newTab"
              href={ExternalUrls.ONE_ON_ONE_USER_GUIDE}
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0px 0px 8px rgb(0 0 0 / 10%)',
                borderRadius: '8px',
                padding: '19px 23px 19px 16px',
                fontSize: '14px',
                fontWeight: 'bold',
                width: '316px',
                marginBottom: '24px',
                background: color('white-100'),
              }}
            >
              {t('LOOK_AT_X', { x: t('ONEONONE_USER_GUIDE') })}
              <FormNext
                css={{
                  stroke: color('resily-orange-100'),
                }}
              />
            </Link>
            <Tips item={tipsItem} />
          </div>
          <OneOnOneDrawer />
        </>
      </Box>
      {isShowUpdateMeetingModal && (
        <OneOnOneEditMeetingScheduleModal
          title={t('EDIT_ONEONONE_MODAL_TITLE')}
          oneOnOneId={oneOnOneId}
          currentOneOnOneMeeting={currentMeeting}
          selectedPartner={{ user: userOneOnOne!.userOneOnOne.partnerUser }}
          onUpsertAndDeleteUserOneOnOneMeetings={handleUpsertAndDeleteUserOneOnOneMeetings}
          onClose={handleUpdateMeetingModalClose}
        />
      )}
      <OneOnOneDeleteModal
        id={currentMeeting ? currentMeeting?.id : ''}
        deleteItemName={
          currentMeeting
            ? generateDeleteItemName(t, currentMeeting.startTime, currentMeeting.endTime)
            : t('ONEONONE')
        }
        deleteItemNameForBody={
          currentMeeting && userOneOnOne
            ? t('X_WITH_Y', {
                x: generateDeleteItemName(t, currentMeeting.startTime, currentMeeting.endTime),
                y: t('COMPELLATION', {
                  fullName: t('FULL_NAME', {
                    firstName: userOneOnOne.userOneOnOne.partnerUser.firstName,
                    lastName: userOneOnOne.userOneOnOne.partnerUser.lastName,
                  }),
                }),
              })
            : t('ONEONONE')
        }
        isShowDeleteModal={isShowDeleteMeetingModal}
        setIsShowDelete={setIsShowDeleteMeetingModal}
        onDeleteCommonFunction={deleteOneOnOneMeeting}
      />
    </PageContent>
  )
}

OneOnOnesDetailContainer.displayName = 'OneOnOnesDetailContainer'
